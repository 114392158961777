<template>
  <h1>{{ msg }}</h1>
</template>

<script>

export default {
    'name': 'FeaturesNoPermission',
    data() {
        return {
            'msg': '抱歉，您没有权限访问此页面!'
        };
    },
    'methods': {}
};
</script>

<style>

</style>
